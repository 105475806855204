import { render, staticRenderFns } from "./daDo.vue?vue&type=template&id=05d3da27&"
import script from "./daDo.vue?vue&type=script&lang=js&"
export * from "./daDo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports