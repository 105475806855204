<template>
  <section class="number-area ptb_50">
    <div class="container">
      <h3 class="mb-4">{{ $t("appMenu.kana.business.positions.title") }}</h3>
      <div class="col-sm-12 ol-lg-12">
        <div class="alert alert-info" role="alert">
          <p>{{ $t("appMenu.kana.business.positions.instruction") }}</p>
        </div>
        <div class="mt-5">
            <div class="greetings-list border border-2">
              <ul class="d-flex flex-wrap w-100">
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2">
                    <div class="d-flex flex-cols w-100">
                      <div class="flex col-4 text-center">
                        <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/positions_elevator.png">
                      </div>
                      <div class="flex col-8">
                      The lowest ranks person will stand near the elevator operation panel. The person will hold the elevator door and the last to come in/out.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
}
</script>
