<template>
  <div class="overflow-hidden">
    <div class="main">
      <HeaderSection />
      <InnerBreadcrumb
        :title="module"
        :sub-title="subtitle"
        :moduleName="menuName"
      />
      <div class="col-sm-12 ol-lg-12">
        <div class="alert alert-warning mt-3" role="alert">
          <p>{{ $t("appMenu.kana.intermediate.listening.instruction") }}</p>
        </div>
        <b-tabs class="mt-4" content-class="mt-3">
          <b-tab title="Listening 1"><Sec1 /></b-tab>
          <b-tab title="Listening 2"><Sec2 /></b-tab>
        </b-tabs>
      </div>
      <FooterSection />
    </div>
  </div>
</template>

<script>
import HeaderSection from '../../HeaderSection/headerTwo'
import InnerBreadcrumb from '../../InnerPages/InnerBreadcrumb/breadcrumbThree.vue'
import FooterSection from '../../FooterSection/footerThree'
import Sec1 from './section3_2'
import Sec2 from './section3_4'
export default {
  name: 'Listening',
  components: {
    HeaderSection,
    InnerBreadcrumb,
    Sec1,
    Sec2,
    FooterSection
  },
  data () {
    return {
      module: this.$t('appMenu.kana.intermediate.listening.title'),
      subtitle: this.$t('appMenu.kana.intermediate.listening.title'),
      menuName: this.$t('appMenu.kana.intermediate.main')
    }
  }
}
</script>
