<template>
  <section class="number-area ptb_30">
    <div class="col-sm-12 ol-lg-12">
          <img
            class="img-fluid rounded mx-auto d-block mt-4"
            alt="Responsive image"
            :src="currImage"
            width="50%"
            @mouseover="viewRomaji('over')"
            @mouseout="viewRomaji('out')"
          />
    </div>
  </section>
</template>
<script>
export default {
  name: 'Market',
  data () {
    return {
      images: {
        out: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-extravocab/colours.jpg',
        over: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-extravocab/colours-romanised.jpg'
      },
      currImage: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-extravocab/colours.jpg'
    }
  },
  methods: {
    viewRomaji (state) {
      this.currImage = this.images[state]
    }
  }
}
</script>
