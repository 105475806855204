<template>
  <section class="number-area ptb_30">
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row>
        <b-col cols="2.5" style="margin-left: 380px; margin-top: 80px">
          <b-card>
            <b-card-text>
              <h5 class="syllable">マレーシアへ きてね！</h5>
              <p>{{ $t("learning.malaysia.desc") }}</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="3">
          <img
            class="img-fluid rounded mx-auto d-block mt-4"
            alt="Responsive image"
            src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset1.png"
            width="70%"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="mt-2" cols="3" v-for="image in images" :key="image.key">
          <b-card
            :img-src="image.url"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <b-card-text>
             <h4 class="syllable"><a  style="cursor:pointer;" @click="play(image.kana)"><u>{{ image.kana}}</u></a></h4>
             <h5 class="mt-2"> {{ image.label}}</h5>
             <p class="mt-2" v-if="image.hasOwnProperty('desc')">{{ image.desc }}</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>
<script>
export default {
  name: 'partTwo',
  data () {
    return {
      images: [
        {
          kana: 'こま',
          label: this.$t('learning.malaysia.image1'),
          desc: this.$t('learning.malaysia.image_desc'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset3.png'
        },
        {
          kana: 'ディムサム',
          label: this.$t('learning.malaysia.image2'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset6.png'
        },
        {
          kana: 'バティック',
          label: this.$t('learning.malaysia.image3'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset7.png'
        },
        {
          kana: 'マレーダンス',
          label: this.$t('learning.malaysia.image4'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset8.png'
        },
        {
          kana: 'パサーマラム',
          label: this.$t('learning.malaysia.image5'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset9.png'
        },
        {
          kana: 'ドドル',
          label: this.$t('learning.malaysia.image6'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset10.png'
        },
        {
          kana: 'ワンタン',
          label: this.$t('learning.malaysia.image7'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset11.png'
        },
        {
          kana: 'ブラチャン',
          label: this.$t('learning.malaysia.image8'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset12.png'
        },
        {
          kana: 'ナシ　クラブ',
          label: this.$t('learning.malaysia.image9'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset13.png'
        },
        {
          kana: 'クトゥパ',
          label: this.$t('learning.malaysia.image10'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset14.png'
        },
        {
          kana: 'オタオタ',
          label: this.$t('learning.malaysia.image11'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset15.png'
        },
        {
          kana: 'サテー',
          label: this.$t('learning.malaysia.image12'),
          url: 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/kana-katakana-malaysia/asset16.png'
        }
      ]
    }
  },
  methods: {
    play (val) {
      switch (val) {
      case 'こま':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/koma.mp3'
        break
      case 'ディムサム':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/dimsum.mp3'
        break
      case 'バティック':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/batik.mp3'
        break
      case 'マレーダンス':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/malay dance.mp3'
        break
      case 'パサーマラム':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/pasar malam.mp3'
        break
      case 'ドドル':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/dodol.mp3'
        break
      case 'ワンタン':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/wantan.mp3'
        break
      case 'ブラチャン':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/belacan.mp3'
        break
      case 'ナシ　クラブ':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/nasi kerabu.mp3'
        break
      case 'クトゥパ':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/ketupat.mp3'
        break
      case 'オタオタ':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/otak-otak.mp3'
        break
      case 'サテー':
        this.soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/Malaysia/satey.mp3'
        break
      }
      const audio = new Audio(this.soundSrc)
      audio.play()
    }
  }
}
</script>
<style scoped>
.card-img-top {
    max-height: 240px;
}
</style>
