<template>
  <section class="ptb_30 overflow-hidden">
    <div class="col-sm-12 col-lg-12">
      <div class="text-center">
        <b-row class="mx-auto" style="display:flex; justify-content:center; width:80%;">
          <h3>Japanese Language Conversation In The Workplace</h3>
          <p class="mt-5">
            <ul style="text-align: left;;">
              <li>Using appropriate language usage in workplace is important to show respect towards the hearer.</li>
              <li>In Japanese language there are a few types of Japanese language pattern according to the hierarchy of the person who you are talking to.</li>
            </ul>
          </p>
        </b-row>
        <b-row class="mx-auto" style="display:flex; justify-content:center; width:80%;">
          <p></p>
          <div class="mt-5">
            <ul>
              <li class="d-flex flex-wrap p-2">
                <p class="p-2">
                  <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/futsukei.png" />
                </p>
                <p class="p-2 mt-4 text-left h5">
                  Casual Form<br />
                  [closed peers and informal situation]
                </p>
              </li>
              <li class="d-flex flex-wrap p-2">
                <p class="p-2">
                  <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/teineikei.png" />
                </p>
                <p class="p-2 mt-4 text-left h5">
                  Polite Form<br />
                  [formal situation or with someone you are not close with]
                </p>
              </li>
              <li class="d-flex flex-wrap p-2">
                <p class="p-2">
                  <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/sonkeigo.png" />
                </p>
                <p class="p-2 mt-4 text-left h5">
                  Respect Form<br />
                  [formal situation when talking towards higher hierarchy person]
                </p>
              </li>
              <li class="d-flex flex-wrap p-2">
                <p class="p-2">
                  <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/kenjougo.png" />
                </p>
                <p class="p-2 mt-4 text-left h5">
                  Humble form<br />
                  [formal situation when talking about yourself to higher hierarchy]
                </p>
              </li>
            </ul>
          </div>
        </b-row>
      </div>
      <div class="text-center">
        <b-row style="display:flex; justify-content: center; width:100%; margin-left:10px; margin-top:50px;">
          <b-col>
            <p class="h6">Your superior might speak casually with you using Futsu kei and you cannot reply them back in Futsu kei form because you are not the same rank as them.
            For example: </p>
            <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_1.jpg" />
            <div style="margin:auto 0;">
              <audio id="a1" controls preload class="allaudio">
                  <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_1.mp3" type="audio/mpeg">
                  Your browser does not support the audio element.
              </audio>
            </div>
            <b-button v-b-modal.modal-3_1_translation class="m-2">Translation</b-button>
            <b-button v-b-modal.modal-3_1_romaji class="m-2">Romaji</b-button>
          </b-col>
          <b-modal id="modal-3_1_translation" size="xl" title="Translation 3-1">
            <img
                alt="Responsive image"
                src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_1_english.png"
                width="100%"
              />
          </b-modal>
          <b-modal id="modal-3_1_romaji" size="xl" title="Romaji 3-1">
            <img
                alt="Responsive image"
                src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_1_romaji.png"
                width="100%"
              />
          </b-modal>
        </b-row>
        <b-row style="display:flex; justify-content: center; width:100%; margin-left:10px; margin-top:50px;">
          <b-col>
            <p class="h6">Other than superior at workplace, the waitress or waiter who is working to serve the public also need to use Sonkei Go</p>
            <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_2.jpg" />
            <div style="margin:auto 0;">
              <audio id="a2" controls preload class="allaudio">
                  <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_2.mp3" type="audio/mpeg">
                  Your browser does not support the audio element.
              </audio>
            </div>
            <b-button v-b-modal.modal-3_2_translation class="m-2">Translation</b-button>
            <b-button v-b-modal.modal-3_2_romaji class="m-2">Romaji</b-button>
          </b-col>
          <b-modal id="modal-3_2_translation" size="xl" button-size="sm" title="Translation 3-2">
            <img
                alt="Responsive image"
                src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_2_english.png"
                width="100%"
              />
          </b-modal>
          <b-modal id="modal-3_2_romaji" size="xl" button-size="sm" title="Romaji 3-2">
            <img
                alt="Responsive image"
                src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/3_2_romaji.png"
                width="100%"
              />
          </b-modal>
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
export default {
}
</script>

<script type="text/javascript">
    $(document).ready(function(){
        let a1 = $('#a1');
        let a2 = $('#a2');
        function pauseall(current_a){
          let a1 = $('#a1');
          let a2 = $('#a2');
          var current = current_a[0].id;
          if(current !== 'a1'){ a1[0].currentTime=0; a1.trigger('pause'); }
          if(current !== 'a2'){ a1[0].currentTime=0; a2.trigger('pause'); }
        }
        a1.on({ play:function(){ pauseall($(this)); } })
        a2.on({ play:function(){ pauseall($(this)); } })
    })
</script>
