<template>
  <div class="overflow-hidden">
    <div class="main">
      <HeaderSection />
      <InnerBreadcrumb :title="module" :sub-title="subtitle" :moduleName="menuName"
        :moduleName2="subMenu"/>
        <div class="col-sm-12 ol-lg-12">
          <div class="alert alert-warning mt-3" role="alert">
            <p>{{ $t("appMenu.kana.business.positions.instruction") }}</p>
          </div>
          <b-tabs class="mt-4" content-class="mt-3">
            <b-tab :title="$t('appMenu.kana.business.positions.meeting.title')">
              <PosMeeting />
            </b-tab>
            <b-tab :title="$t('appMenu.kana.business.positions.elevator.title')">
              <PosElevator />
            </b-tab>
            <b-tab :title="$t('appMenu.kana.business.positions.taxi.title')">
              <PosTaxi />
            </b-tab>
          </b-tabs>
        </div>
      <FooterSection />
    </div>
  </div>
</template>

<script>
import HeaderSection from '../../HeaderSection/headerTwo'
import InnerBreadcrumb from '../../InnerPages/InnerBreadcrumb/breadcrumbThree'
import FooterSection from '../../FooterSection/footerThree'
import PosMeeting from './parts/section_positions_meeting'
import PosElevator from './parts/section_positions_elevator'
import PosTaxi from './parts/section_positions_taxi'

export default {
  name: 'Seating Positions',
  components: {
    HeaderSection,
    InnerBreadcrumb,
    PosMeeting,
    PosElevator,
    PosTaxi,
    FooterSection
  },
  data () {
    return {
      module: this.$t('appMenu.kana.business.positions.title'),
      subtitle: this.$t('appMenu.kana.business.positions.title'),
      menuName: this.$t('appMenu.kana.business.main')
    }
  }
}
</script>
