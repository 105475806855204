<template>
  <section class="number-area ptb_50">
    <div class="container">
      <h3 class="mb-4">{{ $t("appMenu.kana.business.greetings.title") }}</h3>
      <div class="col-sm-12 ol-lg-12">
        <div class="alert alert-info" role="alert">
          <p>{{ $t("appMenu.kana.business.greetings.instruction") }}</p>
        </div>
        <div class="mt-5 text-center">
            <div class="greetings-list border border-2">
              <ul class="d-flex flex-wrap w-100">
                <li class="d-flex flex-cols w-100 justify-content-between border-bottom p-2">
                  <div class="d-flex fs-4">Formal</div>
                  <div class="d-flex fs-4">Casual</div>
                  <div class="d-flex fs-4">
                    <div class="d-flex text-center">Meaning</div>
                  </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom " style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Ohayou Gozaimasu
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a1" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/ohayou.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a1').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Ohayou
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a1a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/ohayou2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a1a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                        Good morning
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Doumo arigatou gozaimasu<br />
                        Arigatou gozaimasu
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a2" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/arigatou.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a2').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Arigatou
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a2a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/arigatou2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a2a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                        Thank you
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Konnichiwa
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a3" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/konnichiwa.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a3').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Konnichiwa
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a3a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/konnichiwa.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a3a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                        Good afternoon
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Konbanwa
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a4" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/konbanwa.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a4').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Konbanwa
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a4a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/konbanwa.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a4a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                        Good evening<br />
                      (When meeting someone at night)
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Oyasuminasai
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a5" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/oyasumi.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a5').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Oyasumi
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a5a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/oyasumi2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a5a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                        Good night<br />
                      (After meeting someone at night and about to leave/ before sleep)
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Moushiwakearimasen<br />
                        Doumo Sumimasen<br />
                        Sumimasen<br />
                        Gomennasai
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a6" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/gomen.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a6').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Gomen<br />
                        Gomen ne
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a6a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/gomen2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a6a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                        I am sorry<br />
                        Sorry
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Sayounara
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a7" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/sayounara.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a7').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Sayounara
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a7a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/sayounara.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a7a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                      Good bye<br />
                      (for long distance parting)
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Mata aimashou
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a8" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/matane.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a8').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Mata ne<br />
                        Jya ne
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a8a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/matane2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a8a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                      Let’s meet again<br />
                      Bye-bye
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Osakini shitsureishimasu
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a9" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/osakini.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a9').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                      Osakini…
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a9a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/osakini2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a9a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                      Let’s meet again<br />
                      Bye-bye
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Douzo yoroshiku onegaishimasu<br />
                        Yoroshiku onegaiitashimasu<br />
                        Yoroshiku onegaishimasu<br />
                        Douzo yoroshiku
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a10" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/yoroshiku.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a9').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Yoroshiku<br />
                        Yoroshiku ne
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a10a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/yoroshiku2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a10a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                      Nice to meet you<br />
                      I will need your help afterwards (please)<br />
                      [Different situation will emphasis different meaning]
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Moshi moshi
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a11" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/moshimoshi.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a11').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Moshi moshi
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a11a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/moshimoshi.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a11a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                      Hello (on phone)
                      </p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 border border-bottom" style="min-height:150px;">
                    <div class="d-flex flex-wrap w-100">
                      <div style="height:120px;">
                        Ohisashiburi desu
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a12" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/hisashiburi.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a12').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <div style="height:120px;">
                        Hisashiburi<br />
                        Ohisashiburi
                      </div>
                      <div class="d-flex w-100">
                        <audio id="a12a" controls preload class="allaudio d-none d-lg-block">
                            <source src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/hisashiburi2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-block d-lg-none w-100 mx-auto p-1" style="height:30px;">
                            <button class="w-100 mb-2" onclick="document.getElementById('a12a').play()">Play</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap w-100 justify-content-center">
                      <p>
                      Long time no see<br />
                      (it’s been a while)
                      </p>
                    </div>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
}
</script>

<script type="text/javascript">
    $(document).ready(function(){
        let a1 = $('#a1');
        let a2 = $('#a2');
        let a3 = $('#a3');
        let a4 = $('#a4');
        let a5 = $('#a5');
        let a6 = $('#a6');
        let a7 = $('#a7');
        let a8 = $('#a8');
        let a9 = $('#a9');
        let a10 = $('#a10');
        let a11 = $('#a11');
        let a12 = $('#a12');
        let a1a = $('#a1a');
        let a2a = $('#a2a');
        let a3a = $('#a3a');
        let a4a = $('#a4a');
        let a5a = $('#a5a');
        let a6a = $('#a6a');
        let a7a = $('#a7a');
        let a8a = $('#a8a');
        let a9a = $('#a9a');
        let a10a = $('#a10a');
        let a11a = $('#a11a');
        let a12a = $('#a12a');
        function pauseall(current_a){
          let a1 = $('#a1');
          let a2 = $('#a2');
          let a3 = $('#a3');
          let a4 = $('#a4');
          let a5 = $('#a5');
          let a6 = $('#a6');
          let a7 = $('#a7');
          let a8 = $('#a8');
          let a9 = $('#a9');
          let a10 = $('#a10');
          let a11 = $('#a11');
          let a12 = $('#a12');
          let a1a = $('#a1a');
          let a2a = $('#a2a');
          let a3a = $('#a3a');
          let a4a = $('#a4a');
          let a5a = $('#a5a');
          let a6a = $('#a6a');
          let a7a = $('#a7a');
          let a8a = $('#a8a');
          let a9a = $('#a9a');
          let a10a = $('#a10a');
          let a11a = $('#a11a');
          let a12a = $('#a12a');
          var current = current_a[0].id;
          if(current !== 'a1'){ a1[0].currentTime=0; a1.trigger('pause'); }
          if(current !== 'a1a'){ a1[0].currentTime=0; a1a.trigger('pause'); }
          if(current !== 'a2'){ a1[0].currentTime=0; a2.trigger('pause'); }
          if(current !== 'a2a'){ a1[0].currentTime=0; a2a.trigger('pause'); }
          if(current !== 'a3'){ a1[0].currentTime=0; a3.trigger('pause'); }
          if(current !== 'a3a'){ a1[0].currentTime=0; a3a.trigger('pause'); }
          if(current !== 'a4'){ a1[0].currentTime=0; a4.trigger('pause'); }
          if(current !== 'a4a'){ a1[0].currentTime=0; a4a.trigger('pause'); }
          if(current !== 'a5'){ a1[0].currentTime=0; a5.trigger('pause'); }
          if(current !== 'a5a'){ a1[0].currentTime=0; a5a.trigger('pause'); }
          if(current !== 'a6'){ a1[0].currentTime=0; a6.trigger('pause'); }
          if(current !== 'a6a'){ a1[0].currentTime=0; a6a.trigger('pause'); }
          if(current !== 'a7'){ a1[0].currentTime=0; a7.trigger('pause'); }
          if(current !== 'a7a'){ a1[0].currentTime=0; a7a.trigger('pause'); }
          if(current !== 'a8'){ a1[0].currentTime=0; a8.trigger('pause'); }
          if(current !== 'a8a'){ a1[0].currentTime=0; a8a.trigger('pause'); }
          if(current !== 'a9'){ a1[0].currentTime=0; a9.trigger('pause'); }
          if(current !== 'a9a'){ a1[0].currentTime=0; a9a.trigger('pause'); }
          if(current !== 'a10'){ a1[0].currentTime=0; a10.trigger('pause'); }
          if(current !== 'a10a'){ a1[0].currentTime=0; a10a.trigger('pause'); }
          if(current !== 'a11'){ a1[0].currentTime=0; a11.trigger('pause'); }
          if(current !== 'a11a'){ a1[0].currentTime=0; a11a.trigger('pause'); }
          if(current !== 'a12'){ a1[0].currentTime=0; a12.trigger('pause'); }
          if(current !== 'a12a'){ a1[0].currentTime=0; a12a.trigger('pause'); }
        }
        a1.on({ play:function(){ pauseall($(this)); } })
        a1a.on({ play:function(){ pauseall($(this)); } })
        a2.on({ play:function(){ pauseall($(this)); } })
        a2a.on({ play:function(){ pauseall($(this)); } })
        a3.on({ play:function(){ pauseall($(this)); } })
        a3a.on({ play:function(){ pauseall($(this)); } })
        a4.on({ play:function(){ pauseall($(this)); } })
        a4a.on({ play:function(){ pauseall($(this)); } })
        a5.on({ play:function(){ pauseall($(this)); } })
        a5a.on({ play:function(){ pauseall($(this)); } })
        a6.on({ play:function(){ pauseall($(this)); } })
        a6a.on({ play:function(){ pauseall($(this)); } })
        a7.on({ play:function(){ pauseall($(this)); } })
        a7a.on({ play:function(){ pauseall($(this)); } })
        a8.on({ play:function(){ pauseall($(this)); } })
        a8a.on({ play:function(){ pauseall($(this)); } })
        a9.on({ play:function(){ pauseall($(this)); } })
        a9a.on({ play:function(){ pauseall($(this)); } })
        a10.on({ play:function(){ pauseall($(this)); } })
        a10a.on({ play:function(){ pauseall($(this)); } })
        a11.on({ play:function(){ pauseall($(this)); } })
        a11a.on({ play:function(){ pauseall($(this)); } })
        a12.on({ play:function(){ pauseall($(this)); } })
        a12a.on({ play:function(){ pauseall($(this)); } })
    })
</script>
<style scoped>
.answer img {
  transition: transform 0.25s ease;
}

.answer img:hover {
  -webkit-transform: scale(1.5); /* or some other value */
  transform: scale(3);
}
</style>
