<template>
  <section class="number-area ptb_30 overflow-hidden">
    <div class="col-sm-12 col-lg-12">
      <div class="maps-of-japan text-center">
        <b-row class="mx-auto" style="display:flex; justify-content:center; width:80%;">
          <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/japan-map.jpeg" width="40%" />
        </b-row>
      </div>
      <div class="maps-of-japan text-center">
        <b-row style="display:inline-flex width:100%; margin-left:10px; margin-top:50px;">
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('hokkaido')" style="width:200px;"
              >Hokkaido <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('sapporo')" style="width:200px;"
              >Sapporo <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('akita')" style="width:200px;"
              >Akita <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('sendai')" style="width:200px;"
              >Sendai <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('honshu')" style="width:200px;"
              >Honshu <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('tokyo')" style="width:200px;"
              >Tokyo <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('fuji-san')" style="width:200px;"
              >Fuji-san <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('kobe')" style="width:200px;"
              >Kobe <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('kyoto')" style="width:200px;"
              >Kyoto <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('nagoya')" style="width:200px;"
              >Nagoya <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('osaka')" style="width:200px;"
              >Osaka <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('hiroshima')" style="width:200px;"
              >Hiroshima <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('fukuoka')" style="width:200px;"
              >Fukuoka <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('shikoku')" style="width:200px;"
              >Shikoku <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <b-button class="bg-dark" @click="play('kyushu')" style="width:200px;"
              >Kyushu <em class="mdi mdi-volume-high"></em
            ></b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'State',
  data () {
    return {
      sounds: ['hokkaido', 'sapporo', 'akita', 'sendai', 'honshu', 'tokyo', 'fuji-san', 'kobe', 'kyoto', 'nagoya', 'osaka', 'hiroshima', 'fukuoka', 'shikoku', 'kyushu']
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'hokkaido':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/hokkaido.mp3'
        break
      case 'sapporo':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/sapporo.mp3'
        break
      case 'akita':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/akita.mp3'
        break
      case 'sendai':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/sendai.mp3'
        break
      case 'honshu':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/honshu.mp3'
        break
      case 'tokyo':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/tokyo.mp3'
        break
      case 'fuji-san':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/fuji-san.mp3'
        break
      case 'kobe':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/kobe.mp3'
        break
      case 'nagoya':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/nagoya.mp3'
        break
      case 'osaka':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/osaka.mp3'
        break
      case 'hiroshima':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/hiroshima.mp3'
        break
      case 'kyoto':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/kyoto.mp3'
        break
      case 'fukuoka':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/fukuoka.mp3'
        break
      case 'shikoku':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/shikoku.mp3'
        break
      case 'kyushu':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/kyushu.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
