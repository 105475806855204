<template>
  <section class="number-area ptb_30 overflow-hidden">
    <div class="col-sm-12 col-lg-12">
      <div class="maps-of-japan text-center">
        <b-row class="mx-auto" style="display:flex; justify-content:center; width:80%;">
          <h3>Always show respect with bowing when greets someone in your workplace</h3>
        </b-row>
      </div>
      <div class="maps-of-japan text-center">
        <b-row style="display:flex; justify-content: center; width:100%; margin-left:10px; margin-top:50px;">
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <h3>Eshaku<span>会釈</span></h3>
            <br />
            <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/bow15.png" />
            <br />
            <br />
            <p style="text-align: center;">Light greeting (casual greeting)</p>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <h3>Keirei<span>敬礼</span></h3>
            <br />
            <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/bow30.png" />
            <br />
            <br />
            <p style="text-align: center;">Respectful greeting (towards clients or superior including apologies  or thankful situation)</p>
          </b-col>
          <b-col cols="1.5" style="width:200px; margin:10px;">
            <h3>Saikeirei<span>最敬礼</span></h3>
            <br />
            <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/bow90.png" />
            <br />
            <br />
            <p style="text-align: center;">The most respectful greeting (showing respect to the VIP, including expressing deep apologies, regretful or great gratitude)</p>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'State',
  data () {
    return {
      sounds: ['hokkaido', 'sapporo', 'akita', 'sendai', 'honshu', 'tokyo', 'fuji-san', 'kobe', 'kyoto', 'nagoya', 'osaka', 'hiroshima', 'fukuoka', 'shikoku', 'kyushu']
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'hokkaido':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/hokkaido.mp3'
        break
      case 'sapporo':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/sapporo.mp3'
        break
      case 'akita':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/akita.mp3'
        break
      case 'sendai':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/sendai.mp3'
        break
      case 'honshu':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/honshu.mp3'
        break
      case 'tokyo':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/tokyo.mp3'
        break
      case 'fuji-san':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/fuji-san.mp3'
        break
      case 'kobe':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/kobe.mp3'
        break
      case 'nagoya':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/nagoya.mp3'
        break
      case 'osaka':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/osaka.mp3'
        break
      case 'hiroshima':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/hiroshima.mp3'
        break
      case 'kyoto':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/kyoto.mp3'
        break
      case 'fukuoka':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/fukuoka.mp3'
        break
      case 'shikoku':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/shikoku.mp3'
        break
      case 'kyushu':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/kyushu.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
