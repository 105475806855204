<template>
  <section class="number-area ptb_30 overflow-hidden">
    <div class="col-sm-12 ol-lg-12">
    <div class="partOne text-center">
        <b-row>
            <b-col cols="1.5" style="margin-left:100px; margin-top: 50px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">ペルリス</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:120px; margin-top: 110px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">ケダ</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:120px; margin-top: 150px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">クランタン</h4>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="1.5" style="margin-left:100px; margin-top: 60px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">ペナン</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:100px; margin-top: 200px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">ペラ</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:320px; margin-top: 90px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">トレンガヌ</h4>
                    </b-card-text>
                </b-card>
            </b-col>
                  <b-col cols="1.5" style="margin-left:630px; margin-top: 80px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">サバ</h4>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="1.5" style="margin-left:270px; margin-top: 160px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">スランゴール</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:190px; margin-top: 230px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">ネグリセンビラン</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:-250px; margin-top: 60px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">パハン</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:470px; margin-top: 80px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">サラワク</h4>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="1.5" style="margin-left:440px; margin-top: 60px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">マラッカ</h4>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="1.5" style="margin-left:190px; margin-top: 80px;">
                <b-card>
                    <b-card-text>
                        <h4 class="syllable">ジョホール</h4>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
    </div>
    <div class="maps-of-japan text-center">
    <b-row style="display:inline-flex width:100%; margin-left:10px; margin-top:50px;">
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('perlis')" style="width:200px;"
            >ペルリス <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('kedah')" style="width:200px;"
            >ケダ <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('penang')" style="width:200px;"
            >ペナン <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('perak')" style="width:200px;"
            >ペラ <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('selangor')" style="width:200px;"
            >スランゴール <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('negeri_sembilan')" style="width:200px;"
            >ネグリセンビラン <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('melaka')" style="width:200px;"
            >マラッカ <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('johor')" style="width:200px;"
            >ジョホール <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('kelantan')" style="width:200px;"
            >クランタン <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('terengganu')" style="width:200px;"
            >トレンガヌ <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('pahang')" style="width:200px;"
            >パハン <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('sabah')" style="width:200px;"
            >サバ <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
        <b-col cols="1.5" style="width:200px; margin:10px;">
        <b-button class="bg-dark" @click="play('sarawak')" style="width:200px;"
            >サラワク <em class="mdi mdi-volume-high"></em
        ></b-button>
        </b-col>
    </b-row>
    </div>
  </section>
</template>
<script>
export default {
  name: 'State',
  data () {
    return {
      sounds: ['perlis', 'kedah', 'penang', 'perak', 'selangor', 'negeri_sembilan', 'melaka', 'johor', 'pahang', 'terengganu', 'kelantan', 'sarawak', 'sabah']
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'perlis':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/perlis.mp3'
        break
      case 'kedah':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/kedah.mp3'
        break
      case 'penang':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/penang.mp3'
        break
      case 'perak':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/perak.mp3'
        break
      case 'selangor':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/selangor.mp3'
        break
      case 'negeri_sembilan':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/negeri_sembilan.mp3'
        break
      case 'melaka':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/melaka.mp3'
        break
      case 'johor':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/johor.mp3'
        break
      case 'pahang':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/pahang.mp3'
        break
      case 'terengganu':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/terengganu.mp3'
        break
      case 'kelantan':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/kelantan.mp3'
        break
      case 'sarawak':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/sarawak.mp3'
        break
      case 'sabah':
        soundSrc = 'https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/sounds/katakana-malaysia/sabah.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
<style scoped>
.partOne {
    background: url('https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/malaysia/asset17.png');
    background-position: center;
    background-size: contain;
    min-height: 1000px;
    background-repeat:no-repeat;
}
</style>
