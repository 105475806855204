<template>
  <header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative kana-navbar" id="headertwo">
      <a class="navbar-brand" id="brand-logo" href="/">
        <img
          class="navbar-brand-regular"
          src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/logo_tifo.png"
          alt="Toshiba International Foundation"
          width="95"
        />
        <img
          class="navbar-brand-regular"
          src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/ukm-logo.png"
          alt="brand-logo"
          width="110"
        />
        <img
          class="navbar-brand-regular"
          src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/pelangi-logo.png"
          alt="Toshiba International Foundation"
          width="55"
          style="background-color: white;"
        />
        <img
          class="navbar-brand-sticky"
          src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/logo_tifo.png"
          alt="Toshiba International Foundation"
          width="95" style="max-width: none;"
        />
        <img
          class="navbar-brand-sticky"
          src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/ukm-logo.png"
          alt="brand-logo"
          width="110" style="max-width: none;"
        />
        <img
          class="navbar-brand-sticky"
          src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/img/pelangi-logo.png"
          alt="Toshiba International Foundation"
          width="55"
        />
      </a>
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="navbarToggler"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>

      <div class="navbar-inner" style="margin-left:30px;">
        <!--  Mobile Menu Toggler -->
        <button
          class="navbar-toggler d-lg-none"
          type="button"
          data-toggle="navbarToggler"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <nav>
          <ul id="navbar-nav" class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                id="navbarDropdownMenuLink"
                class="nav-link dropdown-toggle"
                href="javascript:;"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ this.$t("appMenu.kana.main") }}
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li class="dropdown-submenu">
                  <a class="dropdown-item dropdown-toggle" href="javascript:;"
                    >Hiragana</a
                  >
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="/hiragana-sei-on"
                        >Sei-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/hiragana-daku-on"
                        >Daku-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/hiragana-handaku-on"
                        >Handaku-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/blog-right-sidebar"
                        >You-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/blog-right-sidebar"
                        >Soku-On</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="dropdown-submenu">
                  <a class="dropdown-item dropdown-toggle" href="javascript:;"
                    >Katakana</a
                  >
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="/katakana-sei-on"
                        >Sei-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/blog-three-column"
                        >Daku-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/blog-left-sidebar"
                        >Handaku-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/blog-right-sidebar"
                        >You-On</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/blog-right-sidebar"
                        >Soku-On</a
                      >
                    </li>
                  </ul>
                </li>
                <li>
                  <a class="dropdown-item" href="/number">{{
                    $t("appMenu.kana.number")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/greetings">{{
                    this.$t("appMenu.kana.greeting")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/katakana-malaysia"
                    >Katakana (Malaysia)</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/extravocab">{{
                    this.$t("appMenu.kana.extra.submenu3")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/tips">Tips</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                id="navbarDropdownMenuLink"
                class="nav-link dropdown-toggle"
                href="javascript:;"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t("appMenu.kana.extra.main") }} ({{
                  $t("appMenu.kana.extra.submenu1.main")
                }})
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a class="dropdown-item" href="/unit-one">
                    {{ $t("appMenu.kana.extra.submenu1.title") }} Unit 1</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/unit-two"
                    >{{ $t("appMenu.kana.extra.submenu1.title") }} Unit 2</a
                  >
                </li>
                <li class="dropdown-submenu">
                  <a
                    class="dropdown-item dropdown-toggle"
                    href="javascript:;"
                    >{{ $t("appMenu.kana.extra.submenu1.sub") }}</a
                  >
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="/listening-hiragana"
                        >Hiragana</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/listening-katakana"
                        >Katakana</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                id="navbarDropdownMenuLink"
                class="nav-link dropdown-toggle"
                href="javascript:;"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t("appMenu.kana.extra.main") }} ({{
                  $t("appMenu.kana.extra.submenu2")
                }})
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a class="dropdown-item" href="/nouns">{{
                    this.$t("appMenu.kana.extra.nouns")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/adjectives">{{
                    this.$t("appMenu.kana.extra.adjectives")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/verbs">{{
                    this.$t("appMenu.kana.extra.verbs")
                  }}</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                id="navbarDropdownMenuLink"
                class="nav-link dropdown-toggle"
                href="javascript:;"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t("appMenu.kana.business.main") }}
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a class="dropdown-item" href="/business-greetings">{{
                    this.$t("appMenu.kana.business.greetings.title")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/business-maps">{{
                    this.$t("appMenu.kana.business.maps.title")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/business-positions">{{
                    this.$t("appMenu.kana.business.positions.title")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/business-bow">{{
                    this.$t("appMenu.kana.business.bow.title")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/business-language-usage">{{
                    this.$t("appMenu.kana.business.language-usage.title")
                  }}</a>
                </li>
              </ul>
            </li>
            <li class="nav-item" style="display:none;">
                <a
                  class="nav-link"
                  href="user/resources">
                  Intermediate
                </a>
            </li>
            <li class="nav-item">
                <a
                  class="nav-link"
                  href="user/resources">
                  eResources
                </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data () {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  }
}
</script>
