<template>
  <section class="number-area ptb_50">
    <div class="container">
      <h3 class="mb-4">{{ $t("appMenu.kana.business.positions.title") }}</h3>
      <div class="col-sm-12 ol-lg-12">
        <div class="alert alert-info" role="alert">
          <p>{{ $t("appMenu.kana.business.positions.instruction") }}</p>
        </div>
        <div class="mt-5">
            <div class="greetings-list border border-2">
              <ul class="d-flex flex-wrap w-100">
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2">
                    <div class="d-flex flex-cols w-100">
                      <div class="flex col-4 text-center">
                        上座<br />かみざ<br />Kamiza<br />( Higher hierarchy seat)
                      </div>
                      <div class="flex col-8">
                      - Senior, high level position in management or guest<br />
                      - Seating must be further from the entrance.<br />
                    </div>
                  </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2">
                    <div class="d-flex flex-wrap w-100">
                      <div class="flex col-4 text-center">
                        下座<br />しもざ<br />Shimoza<br />(Lower rank seat)
                      </div>
                      <div class="flex col-8">
                      - Junior staff or low rank position<br />
                      - Seating must be near from the entrance. It is easier for the junior staff to go in and out from the room in case they need to bring or prepare documents/ drinks etc into the meeting room.<br />
                    </div>
                  </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2">
                    <div class="d-flex flex-wrap w-100 p-4">
                      <p class="pb-2">This is the seat chart for the seat position according to the ranks in your company or job.</p>
                      <p class="pb-2">In the seat chart, if you are having guests together in the meeting room or restaurant, the placement of each individual seats according to the ranks will be different.</p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 my-5">
                  <div class="col-6 text-center">
                    <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/positions_meeting1a.png">
                    <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/positions_meeting1.png">
                  </div>
                  <div class="col-6 text-center">
                    <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/positions_meeting1b.png">
                    <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/positions_meeting2.png">
                  </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2">
                    <div class="d-flex flex-wrap w-100 p-4">
                      <p class="pb-2">The highest ranked position in the meeting will sit in the middle facing the clients’ highest position as well. The lowest level in the ranks is placed near the entrance door in a restaurant situation.</p>
                      <p class="pb-2">It is easier for them to do the ordering, passing the beverages and food to other people, and also in charge of the billing and collect money.</p>
                    </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2 mt-5">
                  <div class="col-12 text-center">
                    <img src="https://praktis-jepun-kana.sgp1.digitaloceanspaces.com/business/positions_meeting1c.png" style="width:60%">
                  </div>
                </li>
                <li class="d-flex flex-cols w-100 justify-content-between p-2 pb-2">
                  <div class="d-flex flex-wrap w-100 p-4">
                    <p class="pb-2">If in the office situations, the person nearer to the door should print the meeting materials, provide drinks or any necessary documents. Easy access to the door enables them to handle the job quicker.</p>
                  </div>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
}
</script>
