<template>
  <div class="overflow-hidden">
    <div class="main">
      <HeaderSection />
      <InnerBreadcrumb :title="module" :sub-title="module" :moduleName="menuName"
        :moduleName2="subMenu"/>
      <Sec />
      <FooterSection />
    </div>
  </div>
</template>

<script>
import HeaderSection from '../../HeaderSection/headerTwo'
import InnerBreadcrumb from '../../InnerPages/InnerBreadcrumb/breadcrumbThree'
import FooterSection from '../../FooterSection/footerThree'
import Sec from './parts/section_adjectives'

export default {
  name: 'Adjectives',
  components: {
    HeaderSection,
    InnerBreadcrumb,
    Sec,
    FooterSection
  },
  data () {
    return {
      module: this.$t('appMenu.kana.intermediate.adjectives.title'),
      subtitle: this.$t('appMenu.kana.intermediate.adjectives.title'),
      menuName: this.$t('appMenu.kana.intermediate.main')
    }
  }
}
</script>
